import { Button, ContentWrapper, Header, StyledHTML } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import React from 'react';

const NotFound: React.FC = () => {
  return (
    <>
      <ContentWrapper id="main-content" maxWidth={640} spaceAfter={20} testId="notFound">
        <Header
          hasContentWrapper={false}
          labelBackLink={t`Terug`}
          title={t`Helaas konden wij de pagina niet vinden`}
        />
        <StyledHTML spaceAfter={4}>
          <Trans>
            Wij hebben ons best gedaan maar het lijkt erop dat deze pagina niet (meer) bestaat of de
            pagina verhuisd is of dat je een typefoutje hebt gemaakt. Wij helpen je graag de weg
            terug te vinden. Je kan altijd naar de homepage of de zoekfunctie gebruiken.
          </Trans>
        </StyledHTML>
        <Button href="/" testId="notFoundHomeButton">
          <Trans>Terug naar home</Trans>
        </Button>
      </ContentWrapper>
    </>
  );
};

export { NotFound };
